.c-toggle {
    position: relative;
    width: 50px;
    min-width: 50px;
    height: 30px;
    border-radius: 100px;
    //background-color: #e5e5e7;
    overflow: hidden;
    box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, .05);
    cursor: pointer;

    b {
        display: block;
    }

    &__check {
        display: none;

        &.toggle-disabled ~ .c-toggle__track {
            opacity: 0.4;
            cursor: default;
        }
        &.toggle-desabled ~ .c-toggle__switch {
            cursor: default;
        }

        //&:checked ~ .c-toggle__track {
        //    box-shadow: inset 0 0 0 20px #4284d7;
        //}

        &:checked ~ .c-toggle__switch {
            right: 2px;
            left: 22px;
            transition: .35s cubic-bezier(0.785, 0.135, 0.150, 0.860);
            transition-property: left, right;
            transition-delay: .05s, 0s;
        }
    }

    &__switch {
        position: absolute;
        left: 2px;
        top: 2px;
        bottom: 2px;
        right: 22px;
        background-color: #fff;
        border-radius: 36px;
        z-index: 1;
        transition: .35s cubic-bezier(0.785, 0.135, 0.150, 0.860);
        transition-property: left, right;
        transition-delay: 0s, .05s;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    }

    &__track {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transition: .35s cubic-bezier(0.785, 0.135, 0.150, 0.860);
        background: #E8E8E8;
        border-radius: 40px;
    }

    // Модификаторы
    &.--md {
        width: 48px;
        min-width: 48px;
        height: 24px;

        .c-toggle {
            &__switch {
                right: 26px;
            }
        }

        .c-toggle__check:checked ~ .c-toggle__switch {
            left: 26px;
            right: 2px;
        }
    }
}
