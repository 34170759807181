@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */
@import 'styles/index.scss';

@import 'node_modules/@angular/cdk/overlay-prebuilt.css';
@import 'node_modules/@angular/material/theming';
@import 'node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import "node_modules/moment-timezone-picker/assets/moment-timezone-picker.theme";
@import "node_modules/@ng-select/ng-select/themes/default.theme.css";

$general-typography: mat.define-typography-config(
    $font-family: $font-default
);

//$typography: mat.define-legacy-typography-config();

$theme: mat.define-light-theme(
    (
        typography: $general-typography
    )
);
@include mat.all-component-themes($theme);

// Be sure that you only ever include this mixin once!
@include mat.core();

// TODO: hotfix, should be investigated
.mat-expansion-panel-header-title {
    flex-grow: 1 !important;
}
