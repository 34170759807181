html,
body {
    min-width: 100%;
    height: 100%;
}

html {
    font-size: 16px;
    line-height: 1.4;
}

body {
    font-family: $font-default;
    -webkit-font-smoothing: antialiased;
    color: $clr-black-100;
}

a {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    span + svg,
    svg + span {
        margin-left: 10px;
    }

    &.disabled,
    &[disabled] {
        opacity: .3;
        pointer-events: none;
    }

    &:not([class]) {
        color: var(--clr-link);
    }
}

button {
    &.disabled,
    &[disabled] {
        opacity: .3;
        pointer-events: none;
    }
}

fieldset {
    border: 0;
}


// class
.spacer {
    flex: 1 0 auto;
}

.bold {
    font-weight: 600;
}

.txt-center {
    text-align: center;
}

@include device-sm {
    .m-sm-txt-center {
        text-align: center;
    }
}



// colors
.clr-blue {
    color: $clr-blue-100;
}

.clr-gray {
    color: $clr-black-60;
}


// output
.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-80 {
    margin-top: 80px;
}


@include device-sm {
    .mt-40 {
        margin-top: 30px;
    }
    .mt-60 {
        margin-top: 40px;
    }
    .mt-80 {
        margin-top: 60px;
    }
}
