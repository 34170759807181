// reset style
:root {
    box-sizing: border-box;
}

*,
::before,
::after {
    box-sizing: inherit;
}

* {
    margin: 0;
    padding: 0;
}

a, 
input, 
button,
select,
textarea {outline: none;}

li {
    list-style-type: none;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

svg {
    flex-shrink: 0;
}

input {
    text-overflow: ellipsis;
    overflow: hidden;
}
