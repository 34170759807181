@import "mixin";
@import "variable";

button {
    line-height: 1;
    cursor: pointer;
}

.c-button {
    display: inline-flex;
    align-items: center;
    font-family: $font-nort;
    line-height: 1;

    &[class*='--fill-'] {
        border: 0;
        border-radius: 6px;

        &[class*='blue'] {
            background-color: $clr-blue-10;
            color: $clr-blue-100;
        }

        &[class*='gray'] {
            background-color: $clr-gray-5;
            color: $clr-black-40;
        }

        &[class*='black'] {
            background-color: $clr-black-100;
            color: $clr-white;
        }
    }

    &.--outline-blue {
        border: 1px solid $clr-blue-40;
        border-radius: 40px;
        background-color: transparent;
        color: $clr-blue-100;
    }


    //size
    &.--size-sm {
        height: 30px;
        padding: 0 10px;
        font-size: rem(14);

        svg + span {
            margin-left: 10px;
        }
    }

    &.--size-md {
        height: 40px;
        padding: 0 20px;
        font-size: rem(18);

        svg + span {
            margin-left: 15px;
        }
    }

    &.--size-l {
        height: 60px;
        padding: 0 30px;
        font-size: rem(18);
    }
}
