@font-face {
    font-family: "Nort";
    src: url("../../assets/fonts/Nort.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}
$font-nort: "Nort";

@font-face {
    font-family: "Nort_Medium";
    src: url("../../assets/fonts/Nort_Bold.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Nort_Bold";
    src: url("../../assets/fonts/Nort_Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Nort_Black";
    src: url("../../assets/fonts/Nort_Bold.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "roboto_mono_regular";
    src: local("Roboto Mono"), local("RobotoMono-Regular"),
        url("../../assets/fonts/Robotomono.woff2") format("woff2"),
        url("../../assets/fonts/Robotomono.woff") format("woff"),
        url("../../assets/fonts/Robotomono.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "roboto_mono_bold";
    src: local("Roboto Mono Bold"), local("RobotoMono-Bold"),
        url("../../assets/fonts/Robotomonobold.woff2") format("woff2"),
        url("../../assets/fonts/Robotomonobold.woff") format("woff"),
        url("../../assets/fonts/Robotomonobold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "roboto_mono_medium";
    src: local("Roboto Mono Medium"), local("RobotoMono-Medium"),
        url("../../assets/fonts/Robotomonomedium.woff2") format("woff2"),
        url("../../assets/fonts/Robotomonomedium.woff") format("woff"),
        url("../../assets/fonts/Robotomonomedium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
