.b-loader-circle {
    display: inline-block;
    height: 15px;
    width: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent var(--clr-white) var(--clr-white) var(--clr-white);
    border-radius: 100%;
    animation: clockwise .35s linear infinite;

    &.--size-32 {
        width: 32px;
        height: 32px;
        border-width: 2px;
    }

    &.--size-16 {
        width: 16px;
        height: 16px;
        border-width: 2px;
    }

    &.--black {
        border-color: transparent var(--clr-black-100) var(--clr-black-100) var(--clr-black-100);
    }
}

@keyframes clockwise {
    to {
        transform: rotate(360deg) translatez(0);
    }
}
