.c-card-tariff {
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: var(--clr-black-60);
    background-color: var(--clr-green-10);

    &__name {
        font-size: 18px;
        font-weight: 600;
        color: var(--clr-black-100);
    }

    &__text,
    &__list {
        font-size: 14px;
    }

    &__list {
        &-item {

            strong {
                color: var(--clr-black-100);
            }

            & + & {
                margin-top: 5px;
            }
        }
    }

    &__price {
        display: flex;
        font-size: 24px;
        font-weight: bold;
        color: var(--clr-black-100);

        &-up {
            margin-left: 5px;
            font-size: 14px;
            font-weight: normal;
            color: var(--clr-black-60);
            align-self: flex-start;
        }

        &.--clr-green {
            color: var(--clr-green-100);
        }
    }

    &__period {
        font-size: 14px;
        color: var(--clr-black-60);
    }

    &__old-price {
        margin-top: 10px;
        text-decoration-line: line-through;
    }

    &__button {
        margin-top: 40px;
    }


    //
    &__name + &__text {
        margin-top: 5px;
    }

    &__text + &__list,
    &__list + &__price,
    &__old-price + &__list {
        margin-top: 20px;
    }


    //
    // версия расположенная на страницы тарифы и оплата
    &.--v2 {
        display: flex;
        flex-direction: column;
        padding: 0;
        background-color: transparent;

        .c-card-tariff {

            &__name {
                font-size: 22px;
            }

            &__text {
                min-height: 60px;
            }

            &__price {
                font-size: 38px;
                font-weight: bold;

                &-up {
                    margin-left: 10px;
                }
            }

            &__old-price {
                font-size: 24px;
                font-weight: 600;
                color: var(--clr-black-60);
            }
        }


        .c-card-tariff__text + .c-card-tariff__price,
        .c-card-tariff__price + .c-card-tariff__list {
            margin-top: 20px;
        }
    }


    &.--red {
        background-color: var(--clr-red-10);
    }

    &.--red-light {
        background-color: var(--clr-red-5);
    }

    &.--gray {
        background-color: var(--clr-gray-5);
    }

    &.--green {
        background-color: var(--clr-green-5);
    }
}




.c-tariff-offer {
    padding: 20px;
    background-color: var(--clr-red-10);

    &__title {
        font-size: 18px;

        span {
            display: inline-flex;
            align-items: center;
            height: 30px;
            padding: 0 10px;
            color: var(--clr-white);
            background-color: var(--clr-red-100);
        }
    }

    &__price {
        font-size: 42px;
        font-weight: bold;

        &-old {
            font-size: 24px;
            font-weight: 600;
            text-decoration-line: line-through;
            color: var(--clr-black-60);
            margin-bottom: 20px;

            span {
                font-size: 26px;
                font-weight: 400;
            }
        }
    }

    &__btn {
        margin-top: 20px;
    }


    //
    &__title + &__price,
    &__price + &__price-old{
        margin-top: 10px;
    }


    //
    @include device-l {
    }
}


.c-tariff-stay {
    display: flex;
    align-items: center;

    &__text {
        margin-left: 10px;
        font-size: 14px;
        color: var(--clr-black-60);
    }

    @include device-l {
        flex-direction: column;
        align-items: flex-start;

        &__text {
            margin-left: 0;
            margin-top: 10px;
        }
    }
}

.c-tariffs-footer {
    display: flex;
    align-items: center;

    &__btn {
        margin-left: auto;
    }
}


.c-tariffs-toggle {
    display: flex;
    align-items: center;

    &__text {
        color: var(--clr-black-60);

        &.--active {
            color: var(--clr-black-100);
        }
    }

    &__toggle {
        margin: 0 10px;
    }
}
