.cdk-visually-hidden {
    display: none;
}

.mdc-snackbar__surface {
    max-width: 400px;
    min-width: 260px;
    border-radius: 4px;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    color: var(--clr-white);
    box-shadow: 0 10px 20px rgba(24, 24, 24, 0.2);
    background-color: var(--clr-black-100) !important;
}

.mdc-snackbar__label {
    max-width: 281px;
    padding: 20px !important;
    border-radius: 4px;
}

.mat-mdc-snack-bar-actions {
    margin: 0 0 0 20px;
}

.mdc-button__label {
    background: transparent;
    color: var(--clr-blue-100) !important;
    font-size: 14px !important;
    padding: 0;
    font-weight: 500;
    font-family: inherit;
}
