@use 'sass:math';

// px to rem
// font-size: rem(16);
$browser-context: 16;
@function rem($valueRem, $context: $browser-context) {
    @return #{math.div($valueRem, $context)}rem;
}



// media query
// @include device-lg { font-size: 1rem; }
@mixin device-lg {
    @media (min-width: 1024px) { @content; }
}

@mixin device-l {
    @media (max-width: 1023px) { @content; }
}

@mixin device-md {
    @media (max-width: 1023px) { @content; }
}

@mixin device-sm {
    @media (max-width: 767px) { @content; }
}

@mixin device-xs {
    @media (max-width: 419px) { @content; }
}
