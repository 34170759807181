.c-reg-box {
    padding: 40px;
    background-color: var(--clr-green-10);

    &__container {
        max-width: 520px;
    }

    &__title {
        font-size: 42px;
        line-height: 1.2;
        font-weight: 600;
    }

    &__text {
        font-size: 18px;
    }

    &__descr {
        font-size: 14px;
        color: var(--clr-black-60);
    }

    &__info:not(:empty) {
        margin-top: 40px;
    }


    //
    &__form + &__descr {
        margin-top: 10px;
    }

    &__text + &__form {
        margin-top: 20px;
    }

    &__title + &__text {
        margin-top: 20px;
    }


    //
    @include device-l {
        padding: 20px;

        &__container {
            max-width: 100%;
        }

        &__title {
            font-size: 32px;
        }
    }
}


.c-reg-info {
    display: flex;
    align-items: center;

    &__left,
    &__right {
        flex: 1 1 50%;
        max-width: 50%;
    }

    &__loader {
        display: flex;
        justify-content: center;
    }

    &__left {
        padding-right: 10px;

        > * + * {
            margin-top: 20px;
        }
    }

    &__right {
        padding-left: 10px;
    }

    &__title {
        font-size: 36px;
        font-weight: 600;
    }

    &__text {
        font-size: 18px;
        color: var(--clr-black-60);

        a {
            color: var(--clr-black-100);
            text-decoration: underline;
        }
    }


    //
}
