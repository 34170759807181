
[class*='c-button'] {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-default);
    font-size: 16px;
    color: var(--clr-black-100);
    transition: all .15s linear;

    .c-circle + span,
    svg + span {
        margin-left: 10px;
    }

    &[class*='_txt'] {
        border: 0;
        background-color: transparent;

        &[class*='-gray'] {
            color: var(--clr-black-60);

            &:hover {
                color: var(--clr-blue-60);

                svg {
                    g {
                        fill: var(--clr-blue-60);
                    }
                }
            }
        }

        &[class*='-blue'] {
            color: var(--clr-blue-100);

            .c-circle {
                border-color: var(--clr-blue-100);
            }

            &:hover {
                color: var(--clr-black-100);

                .c-circle {
                    border-color: var(--clr-black-100);
                }
            }
        }
    }

    &[class*='_link'] {
        border: 0;
        color: var(--clr-blue-100);
        background-color: transparent;
    }


    &[class*='_pill'] {
        height: 36px;
        padding: 0 20px;
        border-radius: 36px;
        border: 0;
        font-size: 14px;

        &[class*='-gray'] {
            background-color: var(--clr-black-10);

            &:hover {
                background-color: var(--clr-black-20);
            }
        }

        &[class*='-outline'] {
            border: 1px solid;
            background-color: transparent;

            &[class*='-blue'] {
                color: var(--clr-blue-100);
                border-color: var(--clr-blue-40);

                &:hover {
                    border-color: var(--clr-blue-60);
                }
            }
        }
    }

    &[class*='_outline'] {
        border: 2px solid;
        border-radius: 6px;
        background-color: transparent;

        &[class*='-blue'] {
            border-color: var(--clr-blue-100);
            color: var(--clr-blue-100);
        }

        &[class*='-black'] {
            border-color: var(--clr-black-100);

            &:hover {
                background-color: var(--clr-gray-5);
            }
        }
    }

    &[class*='_fill'] {
        &[class*='-blue'] {
            border: 1px solid darken($clr-blue-100, 10%);
            border-radius: 2px;
            background-color: var(--clr-blue-100);
            color: var(--clr-white);

            &:hover {
                border-color: var(--clr-black-100);
                background-color: var(--clr-black-100);
            }

            &[class*='-light'] {
                color: var(--clr-blue-100);
                border: 1px solid #B3CEEF;
                background-color: var(--clr-blue-20);

                &:hover {
                    background-color: darken($clr-blue-20, 10%);
                }
            }
        }

        &[class*='-gray'] {
            border: 1px solid darken($clr-gray-5, 10%);
            color: var(--clr-black-60);
            background-color: var(--clr-black-10);

            &:hover {
                background-color: darken($clr-gray-5, 10%);
            }
        }


        &[class*='-green'] {
            border: 1px solid darken($clr-green-100, 10%);
            border-radius: 2px;
            color: var(--clr-white);
            box-shadow: 0 2px 4px rgba(24, 24, 24, 0.1);
            background-color: $clr-green-100;

            &:hover {
                background-color: darken($clr-green-100, 10%);
            }


            &[class*='-light'] {
                border-color: darken($clr-green-10, 10%);
                color: var(--clr-green-100);
                background-color: var(--clr-green-10);

                &:hover {
                    background-color: darken($clr-green-10, 10%);
                }
            }
        }


        &[class*='-red'] {
            border: 1px solid darken($clr-red-100, 10%);
            border-radius: 2px;
            color: var(--clr-white);
            box-shadow: 0 2px 4px rgba(24, 24, 24, 0.1);
            background-color: $clr-red-100;

            &:hover {
                background-color: darken($clr-red-100, 10%);
            }

            &[class*='-light'] {
                border-color: darken($clr-red-10, 10%);
                color: var(--clr-red-100);
                background-color: var(--clr-red-10);

                &:hover {
                    background-color: darken($clr-red-10, 10%);
                }
            }
        }


        &[class*='-shadow'] {
            box-shadow: 0 2px 4px rgba(24, 24, 24, 0.1);
        }
    }

    &[class*='_clear'] {
        border: 0;
        border-radius: 2px;

        &[class*='-blue'] {
            background-color: var(--clr-blue-10);
            color: var(--clr-blue-100);

            &:hover {
                background-color: var(--clr-blue-20);
            }
        }

        &[class*='-gray'] {
            background-color: var(--clr-black-10);

            &:hover {
                background-color: var(--clr-black-20);
            }
        }

        &[class*='-lightgray'] {
            background-color: var(--clr-gray-5);

            &:hover {
                background-color: var(--clr-gray-10);
            }
        }
    }

    &[class*='__title'] {
        height: 100%;
        width: 100%;
        color: inherit;
        transition: none;
    }

    &[class*='__loader'] {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        transition: none;
        display: none;
    }

    &.--load {
        [class*='__loader'] {
            display: flex;
        }
        [class*='__title'] {
            opacity: 0;
        }
    }

    // width
    &[class*='_w-100'] {
        width: 100%;
    }

    // height
    &[class*='_h-'] {
        padding: 0 15px;

        &[class*='30'] {
            height: 30px;
        }

        &[class*='40'] {
            height: 40px;
            padding: 0 20px;
        }

        &[class*='50'] {
            height: 50px;
        }

        &[class*='60'] {
            height: 60px;
            padding: 0 20px;
        }
    }

    // height
    &[class*='_mt-'] {

        &[class*='20'] {
            margin-top: 20px;
        }
    }


    // colors
    &[class*='_clr'] {
        &[class*='-gray'] {
            color: var(--clr-black-60);
        }
    }


    // font-size
    &[class*='_f-'] {
        &[class*='14'] {
            font-size: 14px;
        }

        &[class*='18'] {
            font-size: 18px;
        }

        &[class*='24'] {
            font-size: 24px;
        }
    }


    // bg
    &[class*='_bg'] {
        border: 0;

        &[class*='-blue'] {
            background-color: var(--clr-blue-100);
        }
        &[class*='-gray'] {
            background-color: var(--clr-black-10);
        }
    }
}
