:root {
    --r-globalnav-viewport-large-min-width: 1024px;
    --r-globalnav-viewport-large-max-width: 1441px;
    --r-globalnav-viewport-large-query: (min-width: 1024px);
    --r-globalnav-viewport-medium-min-width: 768px;
    --r-globalnav-viewport-medium-max-width: 1023px;
    --r-globalnav-viewport-medium-query: (max-width: 1023px);
    --r-globalnav-viewport-small-min-width: 420px;
    --r-globalnav-viewport-small-max-width: 767px;
    --r-globalnav-viewport-small-query: (max-width: 767px);
    --r-globalnav-viewport-xsmall-min-width: 320px;
    --r-globalnav-viewport-xsmall-max-width: 419px;
    --r-globalnav-viewport-xsmall-query: (max-width: 419px);

    --clr-link: #4284D7;
    --clr-link-disalbed: #A3A3A3;
    --clr-black: #181818;
    --clr-gray: #747474;
    --clr-gray-light: #F5F6F8;

    --clr-black-100: #181818;
    --clr-black-60: #747474;
    --clr-black-40: #A3A3A3;
    --clr-black-20: #D1D1D1;
    --clr-black-10: #E8E8E8;
    --clr-black-5: #F3F3F3;

    --clr-blue-100: #4284D7;
    --clr-blue-60: #8EB5E7;
    --clr-blue-40: #B3CEEF;
    --clr-blue-20: #D9E6F7;
    --clr-blue-10: #ECF3FB;
    --clr-blue-5: #F6F9FD;

    --clr-red-100: #EB5055;
    --clr-red-60: #F39699;
    --clr-red-40: #F7B9BB;
    --clr-red-20: #FBDCDD;
    --clr-red-10: #FDEDEE;
    --clr-red-5: #FEF6F6;

    --clr-green-100: #00B894;
    --clr-green-60: #66D4BF;
    --clr-green-40: #99E3D4;
    --clr-green-20: #CCF1EA;
    --clr-green-10: #E5F8F4;
    --clr-green-5: #F2FBFA;

    --clr-gray-10: #EEF0F4;
    --clr-gray-5: #F5F6F8;

    --clr-white: #FFFFFF;

    --clr-yellow: #E1B12C;

    --font-default: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    --font-nort: "Nort";
    --font-nort-medium: "Nort_Medium";
    --font-nort-bold: "Nort_Bold";
    --font-nort-black: "Nort_Black";
    --font-robot-regular: "roboto_mono_regular";
    --font-robot-bold: "roboto_mono_bold";
    --font-robot-medium: "roboto_mono_medium";

}


// Colors
$clr-black-100: #181818;
$clr-black-60: #747474;
$clr-black-40: #A3A3A3;
$clr-black-20: #D1D1D1;
$clr-black-10: #E8E8E8;
$clr-black-5: #F3F3F3;

$clr-blue-100: #4284D7;
$clr-blue-60: #8EB5E7;
$clr-blue-40: #B3CEEF;
$clr-blue-20: #D9E6F7;
$clr-blue-10: #ECF3FB;
$clr-blue-5: #F6F9FD;

$clr-red-100: #EB5055;
$clr-red-60: #F39699;
$clr-red-40: #F7B9BB;
$clr-red-20: #FBDCDD;
$clr-red-10: #FDEDEE;
$clr-red-5: #FEF6F6;

$clr-green-100: #00B894;
$clr-green-60: #66D4BF;
$clr-green-40: #99E3D4;
$clr-green-20: #CCF1EA;
$clr-green-10: #E5F8F4;
$clr-green-5: #FEF6F6;

$clr-gray-10: #EEF0F4;
$clr-gray-5: #F5F6F8;

$clr-white: #FFFFFF;





// font-size



//
$font-default: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
$font-nort: "Nort";
$font-nort-medium: "Nort_Medium";
$font-nort-bold: "Nort_Bold";
$font-nort-black: "Nort_Black";
$font-robot-regular: "roboto_mono_regular";
$font-robot-bold: "roboto_mono_bold";
$font-robot-medium: "roboto_mono_medium";

$clr-link: $clr-blue-100;
