.c-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid var(--clr-blue-100);

    &__wrap {
        display: flex;
        align-items: center;

        margin-bottom: 10px;

        font-weight: 400;
        color: var(--clr-gray);

        span {
            cursor: pointer;
        }

        .c-circle {
            margin-right: 10px;
        }
    }

    &--disabled {
        border: 1px solid var(--clr-black-40);
    }
}
